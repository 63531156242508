import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { createProject } from '../../redux/slices/createProjectSlice';
import { fetchSports } from '../../redux/slices/sportsSlice';
import { fetchSportCategories } from '../../redux/slices/sportCategoriesSlice';
import { fetchGranteeByUserId } from '../../redux/slices/granteeByUserIdSlice';
import AddressForm from '../forms/AddressForm';


const CreateProject = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user.id;

    const createProjectStatus = useSelector((state) => state.createProject.status);
    const sports = useSelector((state) => state.sports.sports);
    const sportCategories = useSelector((state) => state.sportCategories.sportCategories);
    const grantee = useSelector((state) => state.granteeByUserId.granteeByUserId);

    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [postcode, setPostcode] = useState('');
    const [addressFound, setAddressFound] = useState('');
    const [selectedSportCategory, setSelectedSportCategory] = useState('');
    const [files, setFiles] = useState([]);
    const [eventDates, setEventDates] = useState([{ startDate: '', endDate: '' }]);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
        sportCategoryKey: '',
        sportId: '',
        isOfficial: false,
        isSeasonal: false,
        venue: ''
    });

    useEffect(() => {
        dispatch(fetchSports()); 
        dispatch(fetchSportCategories()); 
        dispatch(fetchGranteeByUserId(userId));
    }, [dispatch, userId]);

    // Pre-fill the form with the grantee detail
    useEffect(() => {
        if (grantee) {
            setFormData(prevData => ({
                ...prevData,
                contactFirstName: grantee.first_name || '',
                contactLastName: grantee.last_name || '',
                contactEmail: grantee.email || '',
            }));
        }
    }, [grantee]);

    const handleSportChange = (event) => {
        setFormData({
            ...formData,
            sportId: event.target.value  // Set as boolean based on value
        });
    };

    const handleSportCategoryChange = (event) => {
        setFormData({
            ...formData,
            sportCategoryKey: event.target.value  
        });
        setSelectedSportCategory(event.target.value);
    };

    const handleIsOfficialChange = (event) => {
        setFormData({
            ...formData,
            isOfficial: event.target.value === "true"  // Set as boolean based on value
        });
    };

    const handleIsSeasonalChange = (event) => {
        setFormData({
            ...formData,
            isSeasonal: event.target.value === "true"  // Set as boolean based on value
        });
    };

    // Update individual event date
    const handleEventDateChange = (index, field, value) => {
        const newEventDates = [...eventDates];
        newEventDates[index][field] = value;
        setEventDates(newEventDates);
    };

    // Add a new date pair
    const handleAddDate = () => {
        setEventDates([...eventDates, { startDate: '', endDate: '' }]);
    };

    // Remove a date pair
    const handleRemoveDate = (index) => {
        setEventDates(eventDates.filter((_, i) => i !== index));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxFiles: 5
    });

    const handleDropzoneRemoveFile = (event, fileName) => {
        event.stopPropagation(); // Prevent the click event from bubbling up to the dropzone
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object
        const formDataWithImages = new FormData();

        // Append form data fields
        Object.entries(formData).forEach(([key, value]) => {
            formDataWithImages.append(key, value);
        });

        // Append event dates as JSON string
        formDataWithImages.append('eventDates', JSON.stringify(eventDates));

        // Append files
        files.forEach((file, index) => {
            formDataWithImages.append(`images`, file); // append each file
        })

        // Append data from AddressForm component
        formDataWithImages.append('addressLine1', addressLine1);
        formDataWithImages.append('addressLine2', addressLine2);
        formDataWithImages.append('city', city);
        formDataWithImages.append('country', country);
        formDataWithImages.append('postcode', postcode);
        formDataWithImages.append('addressFound', addressFound);

        // Dispatch action to create project
        dispatch(createProject(formDataWithImages));

        // Redirect upon successful project creation
        setTimeout(() => {
            navigate('/projects');
        }, 1000);

    }

    // Filter sports based on the selected category
    const filteredSports = selectedSportCategory
        ? sports.filter(sport => sport[selectedSportCategory])
        : sports;

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center mb-5">{t('projects.createProject')}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row gx-3">
                            <div className="col-lg-6 mb-4">
                                <label className="form-label" htmlFor="contactFirstName">{t('sports.contactFirstName')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="contactFirstName"
                                    name="contactFirstName"
                                    autoComplete="given-name"
                                    value={formData.contactFirstName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 mb-4">
                                <label className="form-label" htmlFor="contactLastName">{t('sports.contactLastName')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="contactLastName"
                                    name="contactLastName"
                                    autoComplete="family-name"
                                    value={formData.contactLastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row gx-3">
                            <div className="col-lg-6 mb-4">
                                <label className="form-label" htmlFor="contactEmail">{t('registration.email')}</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    id="contactEmail"
                                    name="contactEmail"
                                    autoComplete="email"
                                    value={formData.contactEmail}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 mb-4">
                                <label className="form-label" htmlFor="contactPhone">{t('registration.phone')}</label>
                                <input
                                    className="form-control"
                                    type="tel"
                                    id="contactPhone"
                                    name="contactPhone"
                                    autoComplete="tel"
                                    value={formData.contactPhone}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        {/* Category Sport Select for sports filtering only */}
                        <p className="mb-2">{t('sports.category')}</p>
                        <select 
                            className="form-select mb-4" 
                            aria-label="Category select"
                            onChange={handleSportCategoryChange}
                        >
                            <option defaultValue="">{t('sports.selectCategory')}</option>
                            {sportCategories.map(category => (
                            <option key={category.key} value={category.key}>{category.name}</option>
                            ))}
                        </select>

                        {/* Sports Select */}
                        <p className="mb-2">{t('sports.sport')}</p>
                        <select 
                            className="form-select mb-4" 
                            aria-label="Sport select"
                            value={formData.sportsId}
                            onChange={handleSportChange}
                            required
                        >
                            <option defaultValue>{t('sports.selectSport')}</option>
                            {filteredSports.map(sport => (
                                <option key={sport.id} value={sport.id}>{sport.name}</option>
                            ))}
                        </select>

                        <div className="form-check mb-2">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="isOfficial" 
                                id="isOfficialTrue"
                                value="true"
                                checked={formData.isOfficial === true}
                                onChange={handleIsOfficialChange}
                            />
                            <label className="form-check-label" htmlFor="isOfficialTrue">
                                {t('sports.official')}
                            </label>
                        </div>
                        <div className="form-check mb-4">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="isOfficial" 
                                id="isOfficialFalse" 
                                value="false"
                                checked={formData.isOfficial === false}
                                onChange={handleIsOfficialChange}
                            />
                            <label className="form-check-label" htmlFor="isOfficialFalse">
                                {t('sports.nonOfficial')}
                            </label>
                        </div>

                        <div className="form-check mb-2">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="isSeasonal" 
                                id="isSeasonalTrue"
                                value="true"
                                checked={formData.isSeasonal === true}
                                onChange={handleIsSeasonalChange}
                            />
                            <label className="form-check-label" htmlFor="isSeasonalTrue">
                                {t('sports.seasonal')}
                            </label>
                        </div>
                        <div className="form-check mb-4">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="isSeasonal" 
                                id="isSeasonalFalse" 
                                value="false"
                                checked={formData.isSeasonal === false}
                                onChange={handleIsSeasonalChange}
                            />
                            <label className="form-check-label" htmlFor="isSeasonalFalse">
                                {t('sports.oneOff')}
                            </label>
                        </div>

                        <div className="mb-4">
                            <label className="form-label" htmlFor="name">{t('projects.name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="name"
                                name="name"
                                autoComplete="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label" htmlFor="description">{t('projects.description')}</label>
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                autoComplete="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                                rows="4"
                            />
                        </div>

                        {/* date and time picker */}
                        <p className="pb-0 mb-2">{t('sports.eventDate')}</p>
                        <ul className="list-group mb-3">
                        {eventDates.map((datePair, index) => (
                            <li key={index} className="list-group-item">
                                <div className="row align-items-center">
                                        <div className="col-lg-5">
                                            <input 
                                                type="datetime-local" 
                                                name="startDate" 
                                                className="form-control"
                                                value={datePair.startDate}
                                                onChange={(e) => handleEventDateChange(index, 'startDate', e.target.value)}
                                                min={new Date().toISOString().slice(0, 16)} // Set minimum date to current datetime
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-1 text-center">to</div>
                                        <div className="col-lg-5">
                                            <input 
                                                type="datetime-local" 
                                                name="endDate" 
                                                className="form-control"
                                                value={datePair.endDate}
                                                onChange={(e) => handleEventDateChange(index, 'endDate', e.target.value)}
                                                min={datePair.startDate || new Date().toISOString().slice(0, 16)}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-1 text-end">
                                            {index > 0 && (
                                                <button 
                                                    type="button" 
                                                    onClick={() => handleRemoveDate(index)}
                                                    className="btn-close" 
                                                    aria-label="Close"
                                                >     
                                                </button>
                                            )}
                                        </div>
                                </div>
                            </li>
                        ))}
                        </ul>
                        <button 
                            type="button" 
                            className="btn btn-outline-secondary text-capitalize mb-4" 
                            onClick={handleAddDate}>+ {t('general.date')}
                        </button>

                        <div className="mb-4">
                            <label className="form-label" htmlFor="venue">{t('sports.venue')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="venue"
                                name="venue"
                                autoComplete="venue"
                                placeholder={t('sports.venuePlaceholder')}
                                value={formData.venue}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <AddressForm
                            setAddressLine1={setAddressLine1}
                            addressLine2={addressLine2}
                            setAddressLine2={setAddressLine2}
                            setCity={setCity}
                            setCountry={setCountry}
                            setPostcode={setPostcode}
                            setAddressFound={setAddressFound}
                        />
                                 
                        <div className="mb-4">
                            <div {...getRootProps({ className: 'dropzone' })} className="dropzone border-secondary-subtle rounded-2">
                                <input {...getInputProps()} />
                                <p className="small text-secondary">{t('projects.dropzone')}</p>
                                <div className="dropzone-file-list">
                                    {files?.map((file, index) => (
                                        <p key={index}>
                                            {file.name}
                                            <button 
                                                type="button" 
                                                className="dropzone-remove-file-button" 
                                                onClick={(e) => handleDropzoneRemoveFile(e, file.name)}
                                            >
                                                &times;
                                            </button>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={createProjectStatus === 'loading'}>
                                {createProjectStatus === 'loading' ? 'Creating Project...' : t('buttons.submit')}
                            </button>
                        </div>
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default CreateProject;