import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for updating projects
export const updateProject = createAsyncThunk('project/updateProject', async ({ formDataWithImages, projectId }) => {
      const response = await fetch(`/api/projects/${projectId}`, {
          method: 'PUT',
          body: formDataWithImages
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Network response was not ok:', errorText);
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
  });
  

const updateProjectSlice = createSlice({
  name: 'project',
  initialState: { project: '', status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProject.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.project = action.payload;
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default updateProjectSlice.reducer;