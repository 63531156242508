import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject } from '../../redux/slices/projectSlice';
import { fetchSponsorsByProjectId } from '../../redux/slices/sponsorsByProjectIdSlice';
import { useTranslation } from 'react-i18next';
import Plans from '../plans/Plans';
import PlansTable from '../plans/PlansTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Projects.css';

function ProjectPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { projectId } = useParams();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;

    const project = useSelector((state) => state.project.project);
    const connectedAccountId = project ? project.connected_account_id : null;
    const sponsors = useSelector((state) => state.sponsorsByProjectId.sponsors) || [];

    const isAuthUser = project?.user_id === userId;

    useEffect(() => {
        dispatch(fetchSponsorsByProjectId(projectId));
        dispatch(fetchProject(projectId));
    }, [dispatch, projectId]);

    const activeSponsors = Array.isArray(sponsors)
        ? sponsors
            .flatMap(activeSponsor => activeSponsor.sponsors || [])
            .filter(sponsor => !sponsor.subscription_cancel_at || new Date(sponsor.subscription_cancel_at) > new Date())
        : [];

    const uniqueActiveSponsors = Array.from(new Set(activeSponsors.map(sponsor => sponsor.sponsor_id)))
        .map(id => activeSponsors.find(sponsor => sponsor.sponsor_id === id));

    return (
        <>
        {/* Start hero */}
        {project.project_images && project.project_images.length > 0 ? (
            <div
                className="bg-primary px-4 py-5 margin-top-58 text-center position-relative"
                style={{
                    backgroundImage: `url(https://storage.googleapis.com/sponsify/projects/${project.project_images[1] || project.project_images[0]})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="overlay hero-overlay z-1"/>
                <div className="py-3 position-relative z-2">
                    <h1 className="display-5 fw-bold text-white text-uppercase">{project.project_name}</h1>
                </div>
            </div>
        ):(
            <div className="bg-primary px-4 py-5 margin-top-58 text-center">
                <div className="py-3">
                    <h1 className="display-5 fw-bold text-white">{project.project_name}</h1>
                </div>
            </div>
        )}
        {/* End hero */}
        <div className="position-sticky mb-3 py-3 bg-white sub-navbar border">
            <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="me-1">
                        {project.grantee_logo ? (
                            <img 
                                className="rounded-circle circular-image"
                                src={`https://storage.googleapis.com/sponsify/grantees-logo/${project.grantee_logo}`} 
                                alt={project.project_name}
                                height="50px"
                                onError={(e) => {
                                    e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                }} 
                            />
                        ):(
                            <div className="circular-container bg-primary text-white fs-3">
                                {project.grantee_name?.charAt(0) || ''}
                            </div>
                        )}  
                    </div>
                    {project.grantee_name}
                </div>
                {/* <div className="d-flex align-items-center mb-3 mb-sm-0">
                    <p className="mb-0"></p>
                    <p className="ms-3 mb-0"></p>
                    <p className="ms-3 mb-0"></p>
                    <p className="ms-3 mb-0"></p>
                </div> */}
                <div className="d-flex align-items-center">
                    {project.grantee_facebook && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_facebook} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-facebook" /></a>
                    )}
                    {project.grantee_instagram && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_instagram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a>
                    )}
                    {project.grantee_tiktok && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_tiktok} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-tiktok" /></a>
                    )}
                    {project.grantee_x && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_x} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-x-twitter" /></a>                       
                    )}
                    {project.grantee_linkedin && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_linkedin} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a>
                    )}
                    {project.grantee_pinterest && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_pinterest} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-pinterest" /></a>
                    )}
                </div>
            </div>
        </div>
        <div className="container"> 
            <p>{project.project_description}</p>  
            <div id="carouselExampleIndicators" className="carousel slide">
                <div className="carousel-indicators pb-3">
                    {project.project_images && project.project_images.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to={index}
                            className={index === 0 ? 'active' : ''}
                            aria-current={index === 0 ? 'true' : 'false'}
                            aria-label={`Slide ${index + 1}`}
                        ></button>
                    ))}
                </div>
                <div className="carousel-inner carousel-img d-lg-flex align-items-center">
                    {project.project_images && project.project_images.map((image, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                            <img
                                src={`https://storage.googleapis.com/sponsify/projects/${image}`}
                                className="d-lg-flex text-center mx-auto"
                                alt={project.project_name}
                                onError={(e) => {
                                    e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                }}
                            />
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <h2 className="mt-5 mb-3">{t('sponsors.sponsors')}</h2>
            <hr className="mb-5"/>
            {uniqueActiveSponsors.length > 0 ? (
                <div className="row">
                    {uniqueActiveSponsors.map((sponsor) => (
                        <div key={sponsor.order_id} className="col-lg-2">
                            <Link to={`/sponsors/${sponsor.sponsor_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="text-center">
                                    <img 
                                        src={`https://storage.googleapis.com/sponsify/sponsors-logo/${sponsor.sponsor_logo}`} 
                                        className="img-thumbnail" 
                                        alt={sponsor.name}
                                        onError={(e) => {
                                            e.target.src = '/images/companies-logo/missing-image.svg';
                                        }}
                                    />
                                    <h4 className="mt-3">{sponsor.sponsor_name}</h4>  
                                </div> 
                            </Link>
                        </div>
                    ))}
                </div>
            ) : (
                <p>{t('sponsors.becomeOurFirstSponsor')}</p>
            )}
            <h2 className="mt-5 mb-3">
                {t('sponsors.becomeOurSponsor')}
                {isAuthUser &&
                    <span className="ms-3">
                        <Link to={`/plan-templates/project/${projectId}`} className="btn btn-primary" type="button">{t('plans.addPlan')}</Link>
                    </span>
                }
            </h2>
            <hr className="mb-5"/>
            {/* Show PlansTable on large screens (lg and up) */}
            <div className="d-none d-lg-block">
                <PlansTable projectId={projectId} connectedAccountId={connectedAccountId} />
            </div>
            {/* Show Plans on small and medium screens (less than lg) */}
            <div className="d-block d-lg-none">
                <Plans projectId={projectId} connectedAccountId={connectedAccountId} />
            </div>
        </div>
        </>
    );
}

export default ProjectPage;
